<template>
   <div>
    <b-modal
      id="directCostCreateModal" 
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Статья расходов
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2 px-1">
        <b-col class="col-6">
          <b-form-group
            label="Какие расходы предстоят?"
            labele-for="directCostTitle" 
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="titleState">
            <b-form-checkbox
              style="position: absolute; top: 0; right: 2rem;"
              v-model="directCostTitleSelector"
              name="choseToSelect"
              value="true">
              Выбрать из списка
            </b-form-checkbox>
            <b-form-input  v-if="!directCostTitleSelector"
              id="direcCostTitle"
              v-model="directCostData.title"
              :state="titleState">
            </b-form-input>
            <custom-dropdown 
              v-if="directCostTitleSelector"
              v-model="titleOptions"
              :defaultItem="directCostData.titleId"
              placeholder="Пожалуйстав, выберите наименование из списка">
            </custom-dropdown>
          </b-form-group>
        </b-col>
        <b-col class="col-6 mt-1">
            <h6 class="mb-1">Укажите характер затрат</h6>
            <p class="fs--1">
              При учете переменных расходов по каждому из потоков выручки, можно оценить, насколько выгоден каждый поток доходов. 
              Если для вам этого не нужно, просто укажите совокупные переменные расходы. 
            </p>
            <b-form-radio-group 
              id="generalDirectCost" 
              v-model="directCostType" 
              name="direc-cost-general">
              <b-col class="col-12 d-flex px-0">
                <b-form-radio 
                  value="general">
                  Общие расходы
                </b-form-radio>
                <b-form-radio 
                  value="incomeStream" 
                  disabled>
                  Расходы по источнику выручки
                </b-form-radio>
              </b-col>
            </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">  
        <b-col sm="12">
          <wizard-section 
            :data="directCostData ? directCostData.values : [0]" 
            unitsTitle="рублей"
            titleHeight="4rem"
            :defaultMax="100000"
            :decimals="0">
            <slot style="height: 1rem;">
              <p class="font=proxima fs--1 mt-2">
                Укажите инфорацию о величине прямых расходов в динамике.  
              </p>
            </slot>
          </wizard-section>
        </b-col>
      </b-row>


      <template v-slot:modal-footer>

        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteDirectCost">
            <i class="far fa-trash-alt mr-2 py-0"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button 
            v-if="loadData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button
            v-if="!loadData"
            variant="outline-success"
            size="sm"
            class="float-right"
            @click="saveData">
            Готово!
          </b-button>
        </div>

      </template>
      
    </b-modal>


  </div>
</template>

<script>
import CustomDropdown from '../widgets/CustomDropdown'
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'

import CFCategories from '../../../store/CFCategories'

export default {
  data: () => ({
    showModal: false,
    loadData: false,
    directCostTitle: '',
    directCostTitleSelector: false,
    directCostSelectedTitle: null,
    directCostType: 'general',
    generalDirectCostValues: {
      0: [null, null, null, null, null, null, null, null, null, null, null, null],
      1: [null, null, null, null, null, null, null, null, null, null, null, null],
      2: [null, null, null, null, null, null, null, null, null, null, null, null],
    },
  }),
  components: {
    CustomDropdown,
    WizardSection,
  },

  computed: {
    directCostData() {
      var data = this.$store.getters.getDirectCostToEdit
      if (data) {
        if (data.titleType == 'system') {
          this.directCostTitleSelector = true
        }
        return data
      } else {
        return {title: '', titleId: null}
      }
      
    },

    titleOptions: {
      get: function() {
        return [
          { id: 0, title: 'Сырье и материалы', cfId: 'dc0' },
          { id: 1, title: 'Товары для перепродажи', cfId: 'dc0' },
          { id: 2, title: 'Рабочий персонал: ФОТ (фонд оплаты труда)', cfId: 'lb0' },
          { id: 3, title: 'Рабочий персонал: начисления на ФОТ', cfId: 'lb1' },
          { id: 4, title: 'Расходы на ТЭР и коммунальные услуги', cfId: 'co1'},
          { id: 5, title: 'Амортизация производственных ОС и НМА', cfId: null },
          { id: 6, title: 'Субподрядные работы', cfId: 'dc2' },
          { id: 7, title: 'Транспортные услуги', cfId: 'dc7'},
          { id: 8, title: 'Вывоз и переработка отходов', cfId: 'co12'},
          { id: 9, title: 'Повышение квалификации кадров', cfId: 'co16'},
          { id: 10, title: 'Прочие работы и услуги', cfId: 'dc9'},
          { id: 11, title: 'Арендная плата за производственные активы', cfId: 'co2'},
          { id: 12, title: 'Расходы (% платежи) на лизинг', cfId: 'fo1'},
          { id: 13, title: 'Ремонт и обслуживание производственных активов', cfId: 'co3'},
          { id: 14, title: 'Командировочные расходы производственного персонала', cfId: 'co14'},
          { id: 15, title: 'Прочие затраты производственного характера', cfId: 'dc9'},
        ]
      },
      set: function(value) {
        if (value) {
          this.directCostSelectedTitle = value
        } else {
          this.directCostSelectedTitle = null
        }

      }
    },

    CFCategories: {
      get: function() {
        var allCategories = []

        allCategories = allCategories.concat(CFCategories.direct)
        allCategories = allCategories.concat(CFCategories.labor)
        allCategories = allCategories.concat(CFCategories.commercial)
        allCategories = allCategories.concat(CFCategories.common)

        return allCategories
      },
      set: function(value) {
         if (value) {
          this.selectedCFCategory = value
        } else {
          this.selectedCFCategory = null
        }
      }
    },

    titleState() {
      
      if (!this.directCostTitleSelector) {
        return this.directCostData.title.length >= 4 ? true : false
      } 

      if (this.directCostTitleSelector && this.directCostSelectedTitle) {
        return true
      } else {
        return false
      }
      
    },

    invalidFeedback() {

      if (this.directCostTitleSelector) {
        if (this.directCostSelectedTitle) {
          return ''
        } else {
          return 'Пожалуйства, выберите наименование из списка...'
        }
      } else {
        if (this.directCostData.title.length >= 4) {
          return ''
        } else if (this.directCostData.title.length > 0) {
          return 'Название должно быть не менее 4-х символов'
        } else {
          return 'Пожалуйства, введине название...'
        }
      } 
    },

    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },
  },

  methods: {
    async saveData() {
      this.loadData = true

      if (!this.titleState) {
        return
      }

      var payload = this.$store.getters.getDirectCostToEdit
      
      payload.type = this.directCostType
      if (this.directCostTitleSelector) {
        payload.title = this.directCostSelectedTitle.title
        payload.titleId = parseInt(this.directCostSelectedTitle.id)
        payload.titleType = 'system'
        payload.cfData = this.CFCategories.find(item => item.id == this.directCostSelectedTitle.cfId)
      } else {
         payload.titleId = null
      }
      payload.titleType = this.directCostTitleSelector ? 'system' : 'custom'

      try {
        if (payload.id) {
          await this.$store.dispatch('updateDirectCostDocument', payload)
        } else {
          await this.$store.dispatch('createDirectCostDocument', payload)
        }
      } catch (error) {
        window.console.log('Unable to Create Direct Cost Document', error)
      }
      
      this.loadData = false
      this.closeModal()
    },

    closeModal() {
      this.showModal = false
    },

    deleteDirectCost() {
      const payload = this.$store.getters.getDirectCostToEdit

      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данный источник расхода?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async (agree) => {
        if (agree) {
          await this.$store.dispatch('deleteDirectCostDocument', payload)
          this.closeModal()
        }
      })
      .catch(err => {
          // An error occurred
          window.console.log('unable to delete direct cost document: ', err)
      })
    }

  }

}
</script>

<style lang="scss" scoped>

.income-title {
  line-height: 90%; 
  margin-top: 2px; 
  margin-bottom: 0.5rem;
}

.income-option {
  padding: 0 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(14, 187, 245, 0.10);
  }
  &.selected {
    background: rgba(14, 187, 245, 0.10);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

</style>